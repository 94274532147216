/*------------------------------------------------------------------------------
| ie.css
| application name v1.0
| Author(s): Richa Avasthi
| Created: 2010-09-03
|
| Internet Explorer-specific styles. Now that we're using JS to tag the specific
| browser version in the body class name, we can combine all those separate
| stylesheets into one, where the styles to target each version are qualified
| by class names.
|
| See <paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/>
| for details.
------------------------------------------------------------------------------*/
/*-----[ typography ]-----------------------------------------------------------
*/
/*----------[ import fonts ]----------------------------------------------------
*/
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:400,400i");
@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  src: local("Roboto"), url(https://fonts.googleapis.com/css?family=Roboto:400,400i) format("woff2"); }

/*----------[ text families ]---------------------------------------------------
*/
/*----------[ text sizes ]------------------------------------------------------
*/
/*----------[ other text properties ]-------------------------------------------
*/
/*-----[ grid ]-----------------------------------------------------------------
| Style specifying the dimensions of the basic page grid and other common
| measures.
*/
/*----------[ susy grid setup ]-------------------------------------------------
| Use this for responsive grid layouts. Tweak as necessary for your needs.
| cf Susy documentation: http://susydocs.oddbird.net/en/latest/
*/
/*----------[ responsive breakpoints ]------------------------------------------
*/
/*----------[ buttons ]---------------------------------------------------------
*/
/*----------[ header ]----------------------------------------------------------
*/
/*-----[ general ]--------------------------------------------------------------
*/
.lte7 .group {
  zoom: 1; }

.lte7 input[type=submit],
.lte7 input[type=button],
.lte7 button,
.lte7 a.button {
  overflow: visible;
  width: 1; }

.ie8 input[type=submit], .ie8 input[type=submit].destructive, .ie8 input[type=submit][disabled],
.ie8 input[type=button],
.ie8 input[type=button].destructive,
.ie8 input[type=button][disabled],
.ie8 button,
.ie8 button.destructive,
.ie8 button[disabled],
.ie8 a.button,
.ie8 a.button.destructive,
.ie8 a.button[disabled],
.ie9 input[type=submit],
.ie9 input[type=submit].destructive,
.ie9 input[type=submit][disabled],
.ie9 input[type=button],
.ie9 input[type=button].destructive,
.ie9 input[type=button][disabled],
.ie9 button,
.ie9 button.destructive,
.ie9 button[disabled],
.ie9 a.button,
.ie9 a.button.destructive,
.ie9 a.button[disabled] {
  padding: 3px 20px; }
